const Color = require('color');

module.exports = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  primary: 'rgb(0,120,191)',
  primaryDarken: 'rgb(0,100,165)',
  primaryLight: 'rgb(60,165,250)',
  primaryDark: 'rgb(0,60,120)',
  grey: 'rgb(179,180,181)',
  greyDark: 'rgb(119,120,121)',
  greyLight: 'rgb(219,220,221)',
  greyText: 'rgb(59,60,61)',
  greyLightText: 'rgb(179,180,181)',
  greyLighter: Color('rgb(179,180,181)').lighten(0.3).rgb().string(),
  red: 'rgb(240,20,20)',
  blue: 'rgb(0,160,220)',
  green: 'rgb(0,190,40)',
  yellow: 'rgb(250,150,0)',
  pageBackground: 'rgb(242,243,244)',
  primaryHover: 'rgb(248,252,255)',
  primaryBackground: 'rgb(245,250,255)',

  secondary: 'rgb(227,30,36)',
  secondaryBackground: 'rgb(245,255,250)',

  highlightColor: 'rgb(245,20,20)',
  disabledBackground: 'rgb(250,250,250)',
};
