export { css as RegistrationPage } from './RegistrationPage/styles';

export {
  ActiveCreditPage,
  AuthPage,
  Avatar,
  Button,
  Card,
  ClientDataUpdatePage,
  Collapse,
  Consents,
  ContractInfo,
  ContractPage,
  ContractsPage,
  CreditChart,
  CreditTable,
  CreditProducts,
  CurrentStatus,
  Disbursement,
  DisclaimerWidget,
  Documents,
  DocumentsPage,
  Footer,
  Header,
  HeaderAuth,
  HeaderRegistration,
  HeaderPhone,
  Icon,
  Icons,
  IndexPage,
  IndividualOffer,
  LandingPage,
  Layout,
  LoadingSpinner,
  LoanAppCreatePage,
  LoanAppCreateWidget,
  LoanAppListPage,
  LoanAppPage,
  Logo,
  MainMenu,
  MainMenuItem,
  Message,
  MessageWidget,
  Modal,
  MoneyMethod,
  Pagination,
  PaymentsPage,
  PaymentsTable,
  PhoneInput,
  Price,
  PrintForm,
  PrintFormDocument,
  Process,
  ProfileMenu,
  ProfilePage,
  ProfileWidget,
  //RegistrationPage,
  RepaymentPage,
  RepaymentWidget,
  RequestButton,
  ResultIcon,
  ScheduleTable,
  SMSProof,
  Start,
  StatefulProcess,
  Steps,
  Subdivision,
  Survey,
  Tag,
} from '@custom/defaults/theme/Components';
