import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/RegistrationPage/styles';

export const css = merge(original, {
  debtCalc: {
    border: '2px solid #0078bf',
    borderRadius: '16px',
    padding: '16px 8px',
    '& .ant-form-item-label': {
      minHeight: '32px',
      display: 'inline-flex',
      alignItems: 'end',
    },
    '& .field-relative': {
      position: 'relative',
      '& .ant-form-item-label': {
        paddingLeft: '28px',
      },
    },
  },
  questionTooltip: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    color: '#0078bf',
    border: '2px solid #0078bf',
    borderRadius: '16px',
    top: '4px',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    cursor: 'default',
    zIndex: '1000',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '42px',
    alignItems: 'flex-start',
    '& button': {
      marginBottom: '1rem',
    },
  },
});

export default css;
